.App {
  background-image: url("../images/background_image.jpg");
  border-style: double;
  border-color: blue;
  border-radius: 12px;
  min-height: 1000px;
}

.App-header {
  font-size: xx-large;
  color: gold;
  padding: 10px;
  text-align: right;
}
.sub-header {
  color: rgb(0, 255, 136);
  margin: 0 60px;
  padding: 10px;
  text-align: right;
}
.icon {
  max-width: 100px;
  max-height: 100px;
}
.logo {
  max-width: 150px;
  max-height: 350px;
}
.card-text {
  color: aqua;
}

.card-title {
  color: aliceblue;
  font-weight: 400;
  font-size: 30px;
}
.meaningshow {
  background: linear-gradient(
    90deg,
    #f7babc,
    #f5dbb7 10%,
    #d5d7a1 20%,
    #aed29e 30%,
    #9ccbcf 40%,
    #a3cfd9 50%,
    #96adc9 60%,
    #9e9dce 70%,
    #b798c7 80%,
    #ce9abf 90%,
    #e5adab
  );
}
.meaningshow > div {
  max-height: 700px;
}

@media screen and (max-width: 420px) {
  .logodiv {
    display: none;
  }
  .icon {
    width: 60px;
  }
  .App-header {
    font-size: medium;
  }
  .card-title {
    font-size: 20px;
  }
  .resultImg {
    width: 100px;
  }
}
@media screen and (min-width: 990px) {
  .resultImg {
    width: 125px;
  }
}
